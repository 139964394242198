
/**
 * testbest First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 window.Vue = require('vue');

 import'./bulma';
 import'./script';

 /*---------------Imports-------------*/
 import VueSweetalert2 from 'vue-sweetalert2';
 import VueSelect from 'vue-select';
 import 'vue-select/dist/vue-select.css' // Add this line

 import Alert from './components/Alert';
 import CustomSelect from "./components/custom-select.vue";

 /*---------------uses-------------*/
 Vue.component('alert', Alert);
 Vue.use(VueSweetalert2);
    Vue.component('custom-select', CustomSelect);

 Vue.component('v-select', VueSelect);
 Vue.use(VueSelect);


 const app = new Vue({
 	el: '#app',
     components: {
            'custom-select': CustomSelect
        }
 });

