<template>
    <form method="POST" :action="formUrl" class="px-5 has-text-right" style="margin-top: 2rem;">
        <input type="hidden" name="_token" v-model="csrfToken">
        <div class="columns" style="flex-direction: row-reverse">
            <div class="column">
                <div class="field">
                    <div class="control">
                        <label for="name" class="form-label">الإسم</label>
                        <input id="name" type="text" class="input is-primary has-text-centered @error('name') is-invalid @enderror" name="name" v-model="name" required autocomplete="given-name" autofocus>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label for="password" class="form-label">كلمة المرور</label>
                        <input id="password" type="password" class="input is-primary has-text-centered @error('password') is-invalid @enderror" name="password" required autocomplete="new-password" v-model="password">
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <div class="control">
                        <label for="phone" class="form-label">رقم الجوال</label>
                        <input id="phone" type="text" class="input is-primary has-text-centered @error('phone') is-invalid @enderror" name="phone" v-model="phone" required autocomplete="tel" autofocus>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label for="password-confirm" class="form-label">تأكيد كلمة المرور</label>
                        <input id="password-confirm" type="password" class="input is-primary has-text-centered" name="password_confirmation" required v-model="password_confirmation" autocomplete="new-password">
                    </div>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label for="email" class="form-label">البريد الإلكترونى</label>
                <input id="email" type="email" class="input is-primary has-text-centered @error('email') is-invalid @enderror" name="email" v-model="email" required autocomplete="email">
            </div>
        </div>
        <div style="display: flex; flex-direction: column; gap: 16px; margin: 28px 0px;">


            <v-select :options="parsedOptions" v-model="level_ids" name="level_ids[]" :reduce="(opts) => opts.value" @input="handleChange" id="level-select" multiple class="orient-select" placeholder="المستويات الدراسية"></v-select>
            <input type="hidden" name="level_ids[]" v-model="level_ids">

            <v-select placeholder="السنين الدراسية" :options="years" :reduce="(opts) => opts.value" v-model="year_ids" name="year_ids[]"
                      @input="handleChangeYears" multiple class="orient-select"></v-select>
            <input type="hidden" name="year_ids[]" v-model="year_ids">

            <v-select placeholder="المواد الدراسية" class="orient-select" :options="subjects" :reduce="(opts) => opts.value" v-model="subject_ids" name="subject_ids[]" multiple></v-select>
            <input type="hidden" name="subject_ids[]" v-model="subject_ids">
        </div>

        <button type="submit" class="button is-gradient has-text-weight-bold px-6" style="width: 100%">
            <span class="px-5">تسجيل</span>
        </button>

    </form>

</template>

<script>
export default {
  name: "Select",
  props: {
    options: {
        type: Array,
      required: true
    },
      url: {
            type: String,
            required: true
      },
      yearsUrl: {
            type: String,
            required: true
      },
      formUrl: {
            type: String,
            required: true
      },
      csrfToken: {
            type: String,
            required: true
      }
  },
    data() {
      return {
          level_ids: [],
          year_ids: [],
          subject_ids: [],
        years: [],
        subjects: [],
          phone: '',
          name: '',
          password: '',
          email: '',
          password_confirmation: ''
      }
    },
  computed: {
    parsedOptions() {
      return JSON.parse(this.options);
    },
    parsedYears() {
      return JSON.parse(this.years);
    },
  },
    methods: {
        handleChange(value) {
            $.ajax({
                url: this.url,
                type: 'GET',
                data: { level_ids: value },
                success: function(response) {
                    this.years = response.map((year) => {
                        return {
                            value: year.id,
                            label: year.name_ar
                        };
                    });
                    $('#year-select').empty();
                    $('#year-select').trigger('change'); // Trigger change to update Select2
                }.bind(this),
                error: function(xhr, status, error) {
                    console.error(error);
                }
            });
        },
        handleChangeYears(value) {
            $.ajax({
                url: '/year_subjects',
                type: 'GET',
                data: { year_ids: value },
                success: function(response) {
                    this.subjects = response.map((subject) => {
                        return {
                            value: subject.id,
                            label: subject.name_ar
                        };
                    });
                    $('#subject-select').empty();
                    $('#subject-select').trigger('change'); // Trigger change to update Select2
                }.bind(this),
                error: function(xhr, status, error) {
                    console.error(error);
                }
            });
        },
    }
};
</script>

<style>
.vs__dropdown-menu {
    direction: rtl !important;
    text-align: right;
    left: unset;
    right: 0;
}

.vs__selected {
    padding: 4px 8px;
    gap: 8px;
}

.orient-select {
    direction: rtl !important;
    text-align: right;
}
</style>
